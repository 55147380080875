import {Person} from "./person.model";

export class User {
  id : number;
  sub: string;
  person: Person;

  constructor(id: number, sub: string, person: Person) {
    this.id = id;
    this.sub = sub;
    this.person = person;
  }
}
